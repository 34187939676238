var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"dense":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.url,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncate(item.title, 50))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]}},{key:"item.ctr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPercent(item.ctr, 2)))])]}},{key:"item.lifetime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.lifetime)))])]}},{key:"item.times_seen",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.times_seen)))])]}},{key:"item.impressions",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.impressions)))])]}},{key:"item.clicks",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.clicks)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }