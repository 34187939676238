<template>
  <div class="gdi-global-stats">
    <v-card class="mt-8 elevation-3">
      <v-card-title>Statistiques Globales</v-card-title>
      <div class="pa-6">
        <GlobalStatsTable />
      </div>
    </v-card>
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <global-nb-created-posts-chart></global-nb-created-posts-chart>
      </v-col>
      <v-col cols="12" md="4" sm="6" v-if="periodType !== 'articles_seen'">
        <global-rate-posts-in-discover></global-rate-posts-in-discover>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <global-top-categories-g-d></global-top-categories-g-d>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import GlobalStatsTable from "@/components/google-discover/gd-insights/tables/GlobalStatsTable.vue";
import GlobalNbCreatedPostsChart from "@/components/google-discover/gd-insights/charts/GlobalNbCreatedPostsChart.vue";
import GlobalRatePostsInDiscover from "@/components/google-discover/gd-insights/charts/GlobalRatePostsInDiscover.vue";
import GlobalTopCategoriesGD from "@/components/google-discover/gd-insights/charts/GlobalTopCategoriesGD.vue";

export default {
  name: "GdiGlobalStats",
  components: {
    GlobalStatsTable,
    GlobalNbCreatedPostsChart,
    GlobalRatePostsInDiscover,
    GlobalTopCategoriesGD,
  },
  data() {
    return {};
  },
  computed: {
    periodType() {
      return this.$store.getters["gd/getPeriodType"];
    },
  },
};
</script>
