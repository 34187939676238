<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      dense
      :hide-default-footer="true"
    >
      <template v-slot:item.created="{ item }">
        <span>{{ item.created.split(" ").slice(0, 1).join("") }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "@axios";
import { formatNumber, formatPercent, truncate } from "@/utils/formatting";
import { getDateStr } from "@/utils/dates";
export default {
  name: "SessionsTopDiscoverPostsTable",
  created() {
    this.getData();
  },
  data() {
    return {
      headers: [
        { text: "Jour", value: "day" },
        { text: "Ratio", value: "ratio" },
        { text: "Impressions", value: "impressions" },
        { text: "Clics", value: "clicks" },
        { text: "CTR", value: "ctr" },
        { text: "Nombre d'articles", value: "nb_posts" },
      ],
      items: [],
    };
  },
  methods: {
    formatNumber,
    formatPercent,
    getData() {
      if (!this.results) return;
      if ("items" in this.results) {
        this.items = this.formatData(this.results.items);
      } else {
        this.items = [];
      }
    },
    truncate(str, n) {
      return truncate(str, n);
    },
    formatData(results) {
      let items = [];
      for (const result of results) {
        let item = {
          day: result.day,
          ratio: formatPercent(result.ratio, 2),
          impressions: formatNumber(result.impressions),
          clicks: formatNumber(result.clicks),
          ctr: formatPercent(result.ctr, 2),
          nb_posts: formatNumber(result.nb_posts),
        };
        items.push(item);
      }
      return items;
    },
    getDateStr,
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["weekdaysStats"];
    },
  },
  watch: {
    results() {
      this.getData();
    },
  },
};
</script>
<style scoped></style>
