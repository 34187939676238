<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      dense
      :hide-default-footer="true"
    >
      <template v-slot:[`item.title`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a :href="item.url" target="_blank" v-bind="attrs" v-on="on">
              {{ truncate(item.title, 50) }}
            </a>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.ctr`]="{ item }">
        <span>{{ formatPercent(item.ctr, 2) }}</span>
      </template>
      <template v-slot:[`item.lifetime`]="{ item }">
        <span>{{ formatNumber(item.lifetime) }}</span>
      </template>
      <template v-slot:[`item.times_seen`]="{ item }">
        <span>{{ formatNumber(item.times_seen) }}</span>
      </template>
      <template v-slot:[`item.impressions`]="{ item }">
        <span>{{ formatNumber(item.impressions) }}</span>
      </template>
      <template v-slot:[`item.clicks`]="{ item }">
        <span>{{ formatNumber(item.clicks) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "@axios";
import { formatNumber, formatPercent, truncate } from "@/utils/formatting";
import { getDateStr } from "@/utils/dates";
export default {
  name: "LifetimeTopDiscoverPostsTable",
  created() {
    this.getData();
  },
  data() {
    return {
      headers: [
        { text: "Title", value: "title" },
        { text: "Jours sur Discover", value: "lifetime" },
        { text: "Première apparition", value: "first_seen" },
        { text: "Dernière apparition", value: "last_seen" },
        { text: "Nombre de jours apparus sur discover", value: "times_seen" },
        { text: "Impressions", value: "impressions" },
        { text: "Clics", value: "clicks" },
        { text: "CTR", value: "ctr" },
      ],
      items: [],
    };
  },
  methods: {
    formatNumber,
    formatPercent,
    getData() {
      if (!this.results) return;
      if ("items" in this.results) {
        this.items = this.formatData(this.results.items);
      } else {
        this.items = [];
      }
    },
    truncate(str, n) {
      return truncate(str, n);
    },
    formatData(results) {
      let items = [];
      for (const result of results) {
        let item = {
          title: result.title,
          url: result.url,
          lifetime: result.lifetime,
          first_seen: result.first_seen,
          last_seen: result.last_seen,
          times_seen: result.times_seen,
          impressions: result.impressions,
          clicks: result.clicks,
          ctr: result.clicks / result.impressions,
        };
        items.push(item);
      }
      return items;
    },
    getDateStr,
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["lifetimeStats"];
    },
  },
  watch: {
    results() {
      this.getData();
    },
  },
};
</script>
<style scoped></style>
