<template>
  <div class="gdi-sessions-stats">
    <v-card class="mt-8 elevation-3">
      <v-card-title>Top 20 des articles </v-card-title>
      <v-card-subtitle>par sessions repris dans Discover</v-card-subtitle>
      <div class="pa-6">
        <SessionsTopDiscoverPostsTable />
      </div>
    </v-card>
    <v-row>
      <v-col cols="12" md="6">
        <sessions-discord-posts-clicks-prints-chart></sessions-discord-posts-clicks-prints-chart>
      </v-col>
      <v-col cols="12" md="6">
        <sessions-ctr-chart></sessions-ctr-chart>
      </v-col>
    </v-row>
    <v-card class="mt-8 elevation-3">
      <v-card-title>Top 20 des articles</v-card-title>
      <v-card-subtitle>
        par sessions qui n'ont pas été repris dans Discover
      </v-card-subtitle>
      <div class="pa-6">
        <SessionsTopNotDiscoverPostsTable />
      </div>
    </v-card>

    <v-row>
      <v-col cols="12">
        <sessions-top-n-d-chart></sessions-top-n-d-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SessionsTopDiscoverPostsTable from "@/components/google-discover/gd-insights/tables/SessionsTopDiscoverPostsTable.vue";
import SessionsTopNotDiscoverPostsTable from "@/components/google-discover/gd-insights/tables/SessionsTopNotDiscoverPostsTable.vue";
import GlobalNbCreatedPostsChart from "@/components/google-discover/gd-insights/charts/GlobalNbCreatedPostsChart.vue";
import SessionsDiscordPostsClicksPrintsChart from "@/components/google-discover/gd-insights/charts/SessionsDiscordPostsClicksPrintsChart.vue";
import SessionsCtrChart from "@/components/google-discover/gd-insights/charts/SessionsCtrChart.vue";
import SessionsTopNDChart from "@/components/google-discover/gd-insights/charts/SessionsTopNDChart.vue";

export default {
  components: {
    SessionsDiscordPostsClicksPrintsChart,
    GlobalNbCreatedPostsChart,
    SessionsTopDiscoverPostsTable,
    SessionsTopNotDiscoverPostsTable,
    SessionsCtrChart,
    SessionsTopNDChart,
  },
};
</script>

<style scoped></style>
