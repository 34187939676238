<template>
  <div class="gdi-weekdays-stats">
    <v-card class="mt-8 elevation-3">
      <div class="pa-6">
        <LifetimeTopDiscoverPostsTable />
      </div>
    </v-card>
    <v-row>
      <v-col cols="12">
        <lifetime-clicks-chart></lifetime-clicks-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LifetimeClicksChart from "@/components/google-discover/gd-insights/charts/LifetimeClicksChart.vue";
import LifetimeTopDiscoverPostsTable from "@/components/google-discover/gd-insights/tables/LifetimeStatsTable.vue";

export default {
  components: {
    LifetimeTopDiscoverPostsTable,
    LifetimeClicksChart,
  },
};
</script>
