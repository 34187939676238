<template>
  <v-card class="mt-8">
    <v-card-title>Jours sur Discover</v-card-title>
    <v-card-subtitle>vs CTR</v-card-subtitle>
    <v-card-text>
      <vue-apex-charts
        v-if="results"
        :height="height"
        :options="chartOptions"
        :series="series"
        type="line"
      />
      <span v-else>{{ noDataText }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatNumber, formatPercent, truncate } from "@/utils/formatting";
import VueApexCharts from "vue-apexcharts";
import { noDataText } from "@/utils/constants";

export default {
  name: "WeekdaysPrintsRatioChart",
  components: {
    VueApexCharts,
  },
  async created() {
    await this.getData();
  },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noDataText,
      height: "355",
      legend: true,
      outlined: false,
      series: [],
      labels: [],
      formatter: (val) => formatNumber(val),
      chartOptions: {
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: this.legend,
          position: "bottom",
          fontSize: "14px",
          fontFamily: '"Inter", sans-serif',
        },
        colors: ["#4285F4", "#EA4335"],
        dataLabels: {
          enabled: this.datalabels,
          textAnchor: "middle",
          style: {
            colors: ["#373D3FFF"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
          },
          offsetY: -30,
          formatter: this.formatter,
          dropShadow: {
            enabled: true,
          },
        },
        yaxis: [
          {
            min: 0,
            forceNiceScale: true,
            title: {
              text: "Jours sur Discover",
            },
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
          {
            min: 0,
            forceNiceScale: true,
            opposite: true,
            title: {
              text: "CTR",
            },
            labels: {
              formatter: (val) => formatPercent(val),
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: [
            {
              formatter: (val) => formatNumber(val),
            },
            {
              formatter: (val) => formatPercent(val),
            },
          ],
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  methods: {
    updateOptions() {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: this.labels,
          labels: {
            hideOverlappingLabels: false,
          },
        },
        series: this.series,
        labels: this.labels,
      };
    },
    async getData() {
      if (!this.results) return;
      if ("items" in this.results) {
        const data = this.results.items;
        this.series = [
          {
            name: "Jours sur Discover",
            type: "column",
            data: data.map((item) => item.lifetime),
          },
          {
            name: "CTR",
            type: "line",
            data: data.map((item) => item.clicks / item.impressions),
          },
        ];
        this.labels = data.map((item) => truncate(item.title, 20));
      } else {
        this.series = [
          {
            name: "Jours sur Discover",
            type: "column",
            data: [],
          },
          {
            name: "CTR",
            type: "line",
            data: [],
          },
        ];
        this.labels = [];
      }
    },
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["lifetimeStats"];
    },
  },
  watch: {
    series() {
      this.updateOptions();
    },
    labels() {
      this.updateOptions();
    },
    results() {
      this.getData();
    },
  },
};
</script>
