<template>
  <div class="gdi-keywords-stats">
    <v-card class="mt-8 elevation-3">
      <v-card-title>Statistiques mots clés</v-card-title>
      <div class="pa-6">
        <v-tabs v-model="tab" align-with-title>
          <v-tab v-for="key of keys" :key="key">
            {{ keywordsData[key].title }}
          </v-tab>
        </v-tabs>
        <!--    <v-card class="mt-6">-->
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="key of keys" :key="key">
            <v-row class="ma-6">
              <v-col cols="6">
                <v-card-subtitle class="text-h7">
                  {{ keywordsData[key].title }} qui sont dans Google Discover
                </v-card-subtitle>
                <KeywordsInGDTable :items="keywordsData[key].dataInGd" />
              </v-col>
              <v-col cols="6">
                <v-card-subtitle class="text-h7">
                  {{ keywordsData[key].title }} qui ne sont pas dans Google
                  Discover
                </v-card-subtitle>
                <KeywordsNotInGDTable :items="keywordsData[key].dataNotInGd" />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </div>
</template>

<style scoped></style>
<script>
import KeywordsInGDTable from "@/components/google-discover/gd-insights/tables/KeywordsInGDTable.vue";
import KeywordsNotInGDTable from "@/components/google-discover/gd-insights/tables/KeywordsNotInGDTable.vue";

export default {
  components: {
    KeywordsInGDTable,
    KeywordsNotInGDTable,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      keys: [
        "proper_nouns",
        "verbs",
        "nouns",
        "adjectives",
        "adverbs",
        "pronouns",
        "punctuations",
        "prepositions",
        "conjunctions",
      ],
      keywordsData: {
        proper_nouns: {
          title: "Nom Propres",
          dataInGd: [],
          dataNotInGd: [],
        },
        verbs: {
          title: "Verbes",
          dataInGd: [],
          dataNotInGd: [],
        },
        nouns: {
          title: "Noms",
          dataInGd: [],
          dataNotInGd: [],
        },
        adjectives: {
          title: "Adjectifs",
          dataInGd: [],
          dataNotInGd: [],
        },
        adverbs: {
          title: "Adverbes",
          dataInGd: [],
          dataNotInGd: [],
        },
        pronouns: {
          title: "Pronoms",
          dataInGd: [],
          dataNotInGd: [],
        },
        punctuations: {
          title: "Ponctuations",
          dataInGd: [],
          dataNotInGd: [],
        },
        prepositions: {
          title: "Prépositions",
          dataInGd: [],
          dataNotInGd: [],
        },
        conjunctions: {
          title: "Conjonctions",
          dataInGd: [],
          dataNotInGd: [],
        },
      },
      tab: "proper_nouns",
      keywordsStats: {},
    };
  },
  methods: {
    getData() {
      if (!this.results) return;
      for (let key of this.keys) {
        if ("in_gd" in this.results) {
          if (key in this.results.in_gd) {
            this.keywordsData[key].dataInGd = [];
            for (let datum of this.results.in_gd[key]) {
              this.keywordsData[key].dataInGd.push({
                keyword: datum[0],
                impressions: datum[1].impressions,
                clicks: datum[1].clicks,
                ctr: datum[1].ctr,
                count: datum[1].count,
              });
            }
          }
        }
        if ("not_in_gd" in this.results) {
          if (key in this.results.not_in_gd) {
            this.keywordsData[key].dataNotInGd = [];
            for (let datum of this.results.not_in_gd[key]) {
              this.keywordsData[key].dataNotInGd.push({
                keyword: datum[0],
                count: datum[1].count,
              });
            }
          }
        }
      }
    },
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["keywordsStats"];
    },
  },
  watch: {
    results() {
      this.getData();
    },
  },
};
</script>
