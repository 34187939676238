<template>
  <bar-chart
    class-names="mt-8"
    title="Articles dans discover"
    subTitle="VS total"
    :series="series"
    :labels="labels"
    :yAxis="yAxis"
    :colors="colors"
    :formatter="formatter"
  ></bar-chart>
</template>

<script>
import BarChart from "@/components/common/charts/BarChart";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "GlobalNbCreatedPostsChart",
  components: {
    BarChart,
  },
  async created() {
    await this.getData();
  },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      series: [],
      labels: [],
      colors: ["#4285F4", "#EA4335"],
      formatter: (val) => formatNumber(val),
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
    };
  },
  methods: {
    async getData() {
      const data = this.results;
      if (!data) return;
      this.series = [
        {
          name: "Nombre d'articles créés sur la période",
          data: [
            data["7"]["nb_posts_total"],
            data["14"]["nb_posts_total"],
            data["30"]["nb_posts_total"],
            data["90"]["nb_posts_total"],
          ],
        },
        {
          name: "Nombre d'articles apparus sur Discover",
          data: [
            data["7"]["nb_posts_in_gd"],
            data["14"]["nb_posts_in_gd"],
            data["30"]["nb_posts_in_gd"],
            data["90"]["nb_posts_in_gd"],
          ],
        },
      ];
      this.labels = ["7 jours", "14 jours", "30 jours", "90 jours"];
    },
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["globalStats"];
    },
  },
  watch: {
    results() {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
