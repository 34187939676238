<template>
  <v-data-table :headers="headers" :items="items" class="elevation-1">
    <template v-slot:item.count="{ item }">
      <span>{{ formatNumber(item.count) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { formatNumber } from "@/utils/formatting";

export default {
  name: "KeywordsNotInGDTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatNumber,
  },
  data() {
    return {
      headers: [
        { text: "Mot clé", value: "keyword" },
        { text: "Count", value: "count" },
      ],
    };
  },
};
</script>

<style scoped></style>
