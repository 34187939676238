<template>
  <v-data-table
    :headers="headers"
    :hide-default-header="true"
    dense
    class="elevation-1"
    :items="items"
    :hide-default-footer="true"
    :items-per-page="11"
  >
    <template v-slot:header="{ props: { headers } }">
      <thead>
        <tr>
          <th v-for="h in headers" :key="h.text">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ h.text }}</span>
              </template>
              <span>{{ h.dateRange }}</span>
            </v-tooltip>
          </th>
        </tr>
      </thead>
    </template>
  </v-data-table>
</template>

<script>
import {
  formatDaysToTime,
  formatPercent,
  formatNumber,
} from "@/utils/formatting";
import moment from "moment";

export default {
  name: "GlobalStatsTable",
  created() {
    this.getData();
    for (const h of this.headers) {
      let endDate = moment().subtract(2, "days").format("DD/MM/YYYY");
      let startDate = moment()
        .subtract(h.range + 2, "days")
        .format("DD/MM/YYYY");
      h.dateRange = `${startDate} - ${endDate}`;
    }
  },
  data() {
    return {
      headers: [
        { text: "", value: "title" },
        {
          text: "7 jours",
          value: "last7Days",
          sortable: false,
          range: 7,
          dateRange: "",
        },
        {
          text: "14 jours",
          value: "last14Days",
          sortable: false,
          range: 14,
          dateRange: "",
        },
        {
          text: "30 jours",
          value: "last30Days",
          sortable: false,
          range: 30,
          dateRange: "",
        },
        {
          text: "90 jours",
          value: "last90Days",
          sortable: false,
          range: 90,
          dateRange: "",
        },
      ],
      items: [],
    };
  },
  methods: {
    formatResult(results) {
      let resultsMap = {
        // time_to_gd: "Combien de temps pour Discover ?",
        // time_to_gd_hours: "Combien de temps pour Discover (en heures) ?",
        // time_in_gd: "Combien de temps un article reste sur discover ?",
        // time_in_gd_hours:
          // "Combien de temps un article reste sur discover (en heures) ?",
        nb_posts_total: "Le nombre d'article créés sur la période",
        nb_posts_in_gd: "Le nombre d'article apparus sur discover",
        ratio_posts_in_gd: "Le taux d'article repris sur discover",
        total_prints_in_gd: "Le nombre total d'impresions sur discover (RMR)",
        real_total_prints_in_gd:
          "Le nombre total d'impresions sur discover (GSC)",
        total_clicks_in_gd: "Le nombre total de clics sur discover (RMR)",
        real_total_clicks_in_gd: "Le nombre total de clics sur discover (GSC)",
        total_sessions_in_gd: "Le nombre total de sessions sur discover",
        total_views_in_gd: "Le nombre total de pages vues sur discover",
      };
      const periodType = this.$store.getters["gd/getPeriodType"];
      if (periodType === "articles_seen") {
        delete resultsMap["nb_posts_total"];
      }
      let items = [];
      for (const [k, v] of Object.entries(resultsMap)) {
        const item = {
          title: v,
        };
        for (const [key, value] of Object.entries(results)) {
          let v = { ...value };
          const dayKey = `last${key}Days`;
          if (k === "time_to_gd" || k === "time_in_gd") {
            v[k] = formatDaysToTime(v[k], false, false);
          } else if (k === "time_to_gd_hours" || k === "time_in_gd_hours") {
            // value[k] = value[k].toFixed(2);
            v[k] = formatNumber(v[k], 1);
          } else if (k === "ratio_posts_in_gd") {
            v[k] = formatPercent(v[k], 2);
          } else {
            v[k] = formatNumber(v[k]);
          }
          item[dayKey] = v[k];
        }
        items.push(item);
      }
      this.items = items;
    },
    getData() {
      if (this.results) {
        this.formatResult(this.results);
      }
    },
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["globalStats"];
    },
  },
  watch: {
    results() {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
