<template>
  <v-data-table :headers="headers" :items="items" class="elevation-1">
    <template v-slot:item.ctr="{ item }">
      <span>{{ formatPercent(item.ctr) }}</span>
    </template>
    <template v-slot:item.impressions="{ item }">
      <span>{{ formatNumber(item.impressions) }}</span>
    </template>
    <template v-slot:item.clicks="{ item }">
      <span>{{ formatNumber(item.clicks) }}</span>
    </template>
    <template v-slot:item.count="{ item }">
      <span>{{ formatNumber(item.count) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { formatPercent, formatNumber } from "@/utils/formatting";
export default {
  name: "KeywordsInGDTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatPercent,
    formatNumber,
  },
  data() {
    return {
      headers: [
        { text: "Mot clé", value: "keyword" },
        { text: "Impressions", value: "impressions" },
        { text: "Clicks", value: "clicks" },
        { text: "CTR", value: "ctr" },
        { text: "Count", value: "count" },
      ],
    };
  },
};
</script>

<style scoped></style>
