<template>
  <v-card class="mt-8">
    <v-card-title>CTR</v-card-title>
    <v-card-subtitle>par article</v-card-subtitle>
    <v-card-text>
      <vue-apex-charts
        v-if="results"
        type="bar"
        :height="height"
        :options="chartOptions"
        :series="series"
      />
      <span v-else>{{ noDataText }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "@/components/common/charts/BarChart.vue";
import VueApexCharts from "vue-apexcharts";
import { noDataText } from "@/utils/constants";
import { formatPercent } from "@/utils/formatting";

export default {
  name: "SessionsDiscordPostsClicksPrintsChart",
  components: { VueApexCharts, BarChart },
  async created() {
    await this.getData();
    setTimeout(() => {
      this.updateOptions();
    }, 10);
  },
  data() {
    return {
      noDataText,
      height: "355",
      // loading: false,
      legend: true,
      outlined: false,
      labels: [],
      series: [
        {
          name: "CTR",
          data: [],
        },
      ],
      colors: ["#4285F4", "#EA4335"],

      chartOptions: {
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        xAxis: { categories: [] },
        legend: {
          show: this.legend,
          position: "bottom",
          fontSize: "14px",
          fontFamily: '"Inter", sans-serif',
        },
        colors: ["#4285F4", "#EA4335"],
        dataLabels: {
          enabled: this.datalabels,
          textAnchor: "middle",
          style: {
            colors: ["#373D3FFF"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
          },
          offsetY: -30,
          dropShadow: {
            enabled: true,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: this.percentFormatter,
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  methods: {
    percentFormatter: (val) => formatPercent(val),
    updateOptions() {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: this.labels,
          labels: {
            formatter: this.percentFormatter,
          },
        },
        series: this.series,
        labels: this.labels,
      };
    },
    async getData() {
      if (!this.results) {
        return;
      }
      if ("items" in this.results) {
        const data = this.results.items;
        this.series = [
          {
            name: "CTR",
            data: data.map((item) => item.ctr),
          },
        ];
        this.labels = data.map((item) => item.title);
      } else {
        this.series = [
          {
            name: "CTR",
            data: [],
          },
        ];
        this.labels = [];
      }
    },
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["sessionsInGD"];
    },
  },
  watch: {
    series() {
      this.updateOptions();
    },
    labels() {
      this.updateOptions();
    },
    results() {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
