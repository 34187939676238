<template>
  <div class="gdi-weekdays-stats">
    <v-card class="mt-8 elevation-3">
      <v-card-title>Statistiques jour de la semaine</v-card-title>
      <div class="pa-6">
        <WeekdaysStatsTable />
      </div>
    </v-card>
    <v-row>
      <v-col cols="12" md="6">
        <weekdays-prints-ratio-chart></weekdays-prints-ratio-chart>
      </v-col>
      <v-col cols="12" md="6">
        <weekdays-ctr-chart></weekdays-ctr-chart>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped></style>
<script>
import WeekdaysStatsTable from "@/components/google-discover/gd-insights/tables/WeekdaysStatsTable.vue";
import WeekdaysPrintsRatioChart from "@/components/google-discover/gd-insights/charts/WeekdaysPrintsRatioChart.vue";
import WeekdaysCtrChart from "@/components/google-discover/gd-insights/charts/WeekdaysCtrChart.vue";

export default {
  components: {
    WeekdaysStatsTable,
    WeekdaysPrintsRatioChart,
    WeekdaysCtrChart,
  },
};
</script>
